<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Novo Registro de Separação de Peças</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalAddData"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
        class="tab-header"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="tabs-just"
        >
          <v-icon
            size="20"
            class=""
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="separator">
            <div
              class="label"
            >
              <span>Dados da partição</span>
            </div>
          </div>

          <div class="line">
            <v-file-input
              ref="fileInput"
              v-model="files"
              :append-icon="icons.mdiPaperclip"
              prepend-icon=""
              label="Inserir planilha de sugestão Honda"
              class="mb-0"
              outlined
              multiple
              chips
              dense
            />

            <v-text-field
              v-model="orderNumber"
              class="input-order"
              label="N° Pedido"
              outlined
              dense
            />
          </div>

          <div
            class="center-div"
          >
            <v-btn
              color="info"
              class="ml-4"
              @click="handleAddOrder"
            >
              <div v-if="!loadingAddOrder">
                <v-icon
                  medium
                  class="me-2"
                >
                  {{ icons.mdiCogClockwise }}
                </v-icon>
                <span>SEPARAR</span>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="separator">
            <div
              class="label"
            >
              <span>Dados do pedido</span>
            </div>
          </div>

          <v-expansion-panels
            v-model="panel"
            multiple
            class="mt-6"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="head-tab">
                <v-icon
                  size="20"
                >
                  {{ icons.mdiStickerCheckOutline }}
                </v-icon>
                <span>Pedido</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                  :headers="headersOrder"
                  :items="itemsOrder"
                  :loading="isLoadingDataOrder"
                  no-data-text="Nenhum registro para exibir"
                  loading-text="Carregando dados..."
                  hide-default-footer
                ></v-data-table>

                <div class="footer-actions-order">
                  <div class="total-orders">
                    <span>Total de pedidos: 4</span>
                  </div>

                  <div />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="head-tab">
                <v-icon
                  size="20"
                >
                  {{ icons.mdiCubeOutline }}
                </v-icon>
                <span>Peças Atacado</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                  :headers="headersOrder"
                  :items="itemsOrder"
                  :loading="isLoadingDataOrder"
                  no-data-text="Nenhum registro para exibir"
                  loading-text="Carregando dados..."
                  hide-default-footer
                ></v-data-table>

                <div class="footer-actions-order">
                  <div class="total-orders">
                    <span>Total de pedidos: 4</span>
                  </div>

                  <v-btn
                    color="greenDark"
                    class=""
                    rounded
                  >
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiArrowCollapseDown }}
                    </v-icon>
                    DOWNLOAD
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="head-tab">
                <v-icon
                  size="20"
                >
                  {{ icons.mdiExportVariant }}
                </v-icon>

                <span>Peças Reposição</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                  :headers="headersOrder"
                  :items="itemsOrder"
                  :loading="isLoadingDataOrder"
                  no-data-text="Nenhum registro para exibir"
                  loading-text="Carregando dados..."
                  hide-default-footer
                ></v-data-table>

                <div class="footer-actions-order">
                  <div class="total-orders">
                    <span>Total de pedidos: 4</span>
                  </div>

                  <v-btn
                    color="greenDark"
                    class=""
                    rounded
                  >
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiArrowCollapseDown }}
                    </v-icon>
                    DOWNLOAD
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiArrowCollapseDown,
  mdiChartDonut,
  mdiClose,
  mdiCogClockwise,
  mdiCubeOutline,
  mdiDownloadOutline,
  mdiExportVariant,
  mdiPackageVariantClosed,
  mdiPaperclip,
  mdiStickerCheckOutline,
} from '@mdi/js'

import utilsMixin from '@/plugins/utilsMixin'

export default {
  mixins: [utilsMixin],

  props: {
    closeModalAdd: { type: Function, default: () => {} },
    updatedTable: { type: Function, default: () => {} },
  },

  data() {
    return {
      loadingAddOrder: false,
      orderNumber: '',
      isLoadingDataOrder: false,
      itemsOrder: [],
      headersOrder: [
        {
          text: 'CÓDIGO',
          value: 'creator.name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'ITEM',
          value: 'creator.company.city',
          sortable: false,
          align: 'center',
        },
        {
          text: 'QUANTIDADE',
          value: 'created_at',
          sortable: true,
          align: 'center',
        },
      ],
      panel: 0,
      files: [],
      tab: '',
      tabs: [
        { title: 'Dados', icon: mdiChartDonut },

        // { title: 'Partição', icon: mdiShapeOutline },
      ],
      icons: {
        mdiClose,
        mdiPaperclip,
        mdiCogClockwise,
        mdiExportVariant,
        mdiDownloadOutline,
        mdiArrowCollapseDown,
        mdiPackageVariantClosed,
        mdiCubeOutline,
        mdiStickerCheckOutline,
      },
    }
  },

  methods: {
    handleCloseModalAddData() {
      this.closeModalAdd()

      this.orderNumber = ''
      this.files = []
    },

    async handleAddOrder() {
      this.loadingAddOrder = true

      try {
        await this.sendFiles('/api/v1/sgq/honda_purchase_order/import_purchase_order', this.files, { code: this.orderNumber })
      } catch (error) {
        console.log('Error: ', error)

        this.$swal({
          icon: 'error',
          title: 'Erro!',
          showConfirmButton: false,
          text: 'Erro ao separar peças',
          timer: 3000,
        })
      } finally {
        this.loadingAddOrder = false

        this.handleCloseModalAddData()

        this.updatedTable()
      }
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.head-modal span{
  font-size: 20px;
  color: #CAC9CD
}

.tabs-just{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tab-header{
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 30px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 25px;
}

.input-order{
  width: 30%;
}

.center-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-actions-order{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.total-orders{
  border-top: 1px solid #F1F1F180;
  padding-top: 5px;
  font-size: 14px;
}

.head-tab{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-tab span{
  width: 90%;
}
</style>
