<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  ></v-skeleton-loader>
  <div v-else>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Editar Registro de Separação de Peças</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalEditData"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="tabs-just"
        >
          <v-icon
            size="20"
            class=""
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="separator">
            <div
              class="label"
            >
              <span>Dados da partição</span>
            </div>
          </div>

          <div class="line">
            <v-file-input
              v-if="orderStatus !== 'FECHADO'"
              ref="fileInput"
              v-model="files"
              :append-icon="icons.mdiPaperclip"
              prepend-icon=""
              label="Inserir planilha de sugestão Honda"
              class="mb-0"
              outlined
              multiple
              chips
              dense
            />

            <v-text-field
              v-model="orderNumber"
              class="input-order"
              label="N° Pedido"
              outlined
              dense
              disabled
            />
          </div>

          <div
            v-if="orderStatus !== 'FECHADO'"
            class="center-div"
          >
            <v-btn
              color="info"
              class="ml-4"
              @click="handleAddOrder"
            >
              <div v-if="!loadingAddOrder">
                <v-icon
                  medium
                  class="me-2"
                >
                  {{ icons.mdiCogClockwise }}
                </v-icon>
                <span>SEPARAR</span>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="separator">
            <div
              class="label"
            >
              <span>Dados do pedido</span>
            </div>
          </div>

          <v-expansion-panels
            v-model="panel"
            multiple
            class="mt-6"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="head-tab">
                <v-icon
                  size="20"
                >
                  {{ icons.mdiStickerCheckOutline }}
                </v-icon>
                <span>Pedido</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                  :headers="headerOrderCloud"
                  :items="listOrder"
                  :loading="isLoadingDataOrder"
                  no-data-text="Nenhum registro para exibir"
                  loading-text="Carregando dados..."
                ></v-data-table>

                <div class="footer-actions-order">
                  <div class="total-orders">
                    <span>Total de pedidos: {{ listOrder.length }}</span>
                  </div>

                  <div />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="head-tab">
                <v-icon
                  size="20"
                >
                  {{ icons.mdiCubeOutline }}
                </v-icon>
                <span>Peças Atacado</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                  :headers="headersOrder"
                  :items="listWholesaleParts"
                  :loading="isLoadingDataOrder"
                  no-data-text="Nenhum registro para exibir"
                  loading-text="Carregando dados..."
                ></v-data-table>

                <div class="footer-actions-order">
                  <div class="total-orders">
                    <span>Total de pedidos: {{ listWholesaleParts.length }}</span>
                  </div>

                  <v-btn
                    v-if="orderStatus !== 'FECHADO'"
                    color="greenDark"
                    class=""
                    rounded
                    @click="getTxt(listWholesaleParts)"
                  >
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiArrowCollapseDown }}
                    </v-icon>
                    DOWNLOAD
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="head-tab">
                <v-icon
                  size="20"
                >
                  {{ icons.mdiExportVariant }}
                </v-icon>

                <span>Peças Reposição</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                  :headers="headersOrder"
                  :items="listSpareParts"
                  :loading="isLoadingDataOrder"
                  no-data-text="Nenhum registro para exibir"
                  loading-text="Carregando dados..."
                ></v-data-table>

                <div class="footer-actions-order">
                  <div class="total-orders">
                    <span>Total de pedidos: {{ listSpareParts.length }}</span>
                  </div>

                  <v-btn
                    v-if="orderStatus !== 'FECHADO'"
                    color="greenDark"
                    class=""
                    rounded
                    @click="getTxt(listSpareParts)"
                  >
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiArrowCollapseDown }}
                    </v-icon>
                    DOWNLOAD
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import utilsMixin from '@/plugins/utilsMixin'
import {
  mdiArrowCollapseDown,
  mdiChartDonut,
  mdiClose,
  mdiCogClockwise,
  mdiCubeOutline,
  mdiDownloadOutline,
  mdiExportVariant,
  mdiPackageVariantClosed,
  mdiPaperclip,
  mdiShapeOutline,
  mdiStickerCheckOutline,
} from '@mdi/js'

export default {
  mixins: [utilsMixin],
  props: {
    id: {
      type: [String],
      required: true,
    },
    closeModalEdit: { type: Function, default: () => {} },
    updatedTable: { type: Function, default: () => {} },
  },

  data() {
    return {
      listOrder: [],
      listWholesaleParts: [],
      listSpareParts: [],
      skeleton: true,
      orderNumber: '',
      orderStatus: '',
      isLoadingDataOrder: false,
      itemsOrder: [],
      headerOrderCloud: [
        {
          text: 'CÓDIGO',
          value: 'codigoitem',
          sortable: false,
          align: 'left',
        },
        {
          text: 'ITEM',
          value: 'descricaoitem',
          sortable: false,
          align: 'left',
        },
        {
          text: 'QUANTIDADE',
          value: 'quantidade',
          sortable: true,
          align: 'center',
        },
      ],
      headersOrder: [
        {
          text: 'CÓDIGO',
          value: 'code_item',
          sortable: false,
          align: 'left',
        },
        {
          text: 'ITEM',
          value: 'description',
          sortable: false,
          align: 'left',
        },
        {
          text: 'QUANTIDADE',
          value: 'amount',
          sortable: true,
          align: 'center',
        },
      ],
      panel: 0,
      files: [],
      tab: '',
      tabs: [
        { title: 'Dados', icon: mdiChartDonut },
        { title: 'Partição', icon: mdiShapeOutline },
      ],
      icons: {
        mdiClose,
        mdiPaperclip,
        mdiCogClockwise,
        mdiExportVariant,
        mdiDownloadOutline,
        mdiArrowCollapseDown,
        mdiPackageVariantClosed,
        mdiCubeOutline,
        mdiStickerCheckOutline,
      },
      loadingAddOrder: false,
    }
  },

  async created() {
    // if (!this.data?.id) {
    //   this.handleCloseModalEditData()

    //   return
    // }

    try {
      const response = await axiosIns.get(`/api/v1/sgq/separation_part/edit/${this.id}`)

      this.orderNumber = response.data.data.separation_part.request_number

      this.orderStatus = response.data.data.separation_part.status

      this.listOrder = response.data.data.data_cloud

      response.data.data.separation_part.honda_purchase_order.map(item => {
        if (item.status === 'wholesale') this.listWholesaleParts.push(item)

        if (item.status === 'replacement') this.listSpareParts.push(item)
      })
    } catch (error) {
      this.$swal({
        icon: 'error',
        title: 'Erro!',
        showConfirmButton: false,
        text: 'Erro ao buscar os dados na API!',
        timer: 3000,
      })
    } finally {
      this.skeleton = false
    }
  },

  methods: {
    handleCloseModalEditData() {
      this.closeModalEdit()

      // this.orderNumber = ''
      this.files = []
    },

    getTxt(list) {
      let content = ''

      list.forEach(item => {
        const partCode = String(item.code_item).padEnd(20, ' ')
        const quantity = String(item.amount).padStart(6, '0')

        content += `${partCode}${quantity}\n`
      })

      const blob = new Blob([content], { type: 'text/plain' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${list[0].status === 'replacement' ? 'ZREP' : 'ZATC'}.txt`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)

      return a
    },

    async handleAddOrder() {
      this.loadingAddOrder = true

      try {
        await this.sendFiles('/api/v1/sgq/honda_purchase_order/import_purchase_order', this.files, { code: this.orderNumber })

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Peças separadas com sucesso',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          showConfirmButton: false,
          text: 'Erro ao separar peças',
          timer: 3000,
        })
      } finally {
        this.loadingAddOrder = false

        this.handleCloseModalEditData()

        this.updatedTable()
      }
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.head-modal span{
    font-size: 20px;
    color: #CAC9CD
  }

.tabs-just{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 30px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 25px;
}

.input-order{
  width: 30%;
}

.center-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-actions-order{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.total-orders{
  border-top: 1px solid #F1F1F180;
  padding-top: 5px;
  font-size: 14px;
}

.head-tab{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-tab span{
  width: 90%;
}
</style>
