import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('di',[_c(VCard,[_c(VCardTitle,{staticClass:"pt-0 pb-0"},[_c('span',{staticClass:"title-page"},[_vm._v("Separação de peças")]),_c(VSpacer),_c(VTextField,{staticClass:"mt-6",attrs:{"label":"Buscar por N° pedido, responsável, data e loja","outlined":"","dense":""}}),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"info","outlined":""},on:{"click":function($event){_vm.showModalAddOrder = true}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" NOVO REGISTRO ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.listData,"loading":_vm.isLoadingData,"no-data-text":"Nenhum registro para exibir","loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"warning"},on:{"click":function($event){return _vm.handleGetDataById(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]),_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":item.status === 'FECHADO' ? 'secondary' : 'success'},on:{"click":function($event){item.status === 'ABERTO' ? _vm.handleOpenModalConfirmStatus(item) : undefined}}},[_vm._v(" "+_vm._s(item.status === 'FECHADO' ? _vm.icons.mdiLock : _vm.icons.mdiLockOpenVariant)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",attrs:{"text":"","color":_vm.getStatusColor(item)}},[_vm._v(" "+_vm._s(item.status ? item.status : '')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}}])})],1),_c(VDialog,{attrs:{"width":"800px"},model:{value:(_vm.showModalAddOrder),callback:function ($$v) {_vm.showModalAddOrder=$$v},expression:"showModalAddOrder"}},[_c('ModalAddOrder',{attrs:{"close-modal-add":function () { return _vm.showModalAddOrder = false; },"updated-table":function () { return _vm.handleListData(); }}})],1),_c(VDialog,{attrs:{"width":"800px"},model:{value:(_vm.showModalEditOrder),callback:function ($$v) {_vm.showModalEditOrder=$$v},expression:"showModalEditOrder"}},[_c('ModalEditOrder',{key:_vm.idToEditOrder,attrs:{"id":_vm.idToEditOrder,"close-modal-edit":_vm.closeModalEdit,"updated-table":function () { return _vm.handleListData(); }}})],1),_c(VDialog,{attrs:{"width":"400px"},model:{value:(_vm.showModalConfirm),callback:function ($$v) {_vm.showModalConfirm=$$v},expression:"showModalConfirm"}},[_c('ModalConfirmStatus',{key:_vm.idToConfirm,attrs:{"id":_vm.idToConfirm,"close-modal-confirm":_vm.closeModalConfirmOrder,"updated-table":function () { return _vm.handleListData(); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }