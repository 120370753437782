<template>
  <div class="container-general">
    <img src="../../../../assets/alert.svg" />

    <h2>Tem certeza?</h2>

    <span>Ao clicar em sim, o processo será fechado</span>
    <span>e não poderá ser mais editado.</span>

    <div class="container-actions">
      <button
        class="btn-not"
        @click="handleCloseModalConfirm"
      >
        Não
      </button>

      <button
        color="success"
        class="btn-yes"
        @click="handleEditOrder"
      >
        <span v-if="!loadingEdit">Sim</span>

        <v-progress-circular
          v-else
          color="white"
          indeterminate
        >
        </v-progress-circular>
      </button>
    </div>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiClose } from '@mdi/js'

export default {
  components: {
  },

  props: {
    id: {
      type: [String],
      required: true,
    },
    closeModalConfirm: { type: Function, default: () => {} },
    updatedTable: { type: Function, default: () => {} },
  },

  data() {
    return {
      showModalCancel: false,
      icons: {
        mdiClose,
      },
      loadingEdit: false,
    }
  },

  methods: {
    handleCloseModalConfirm() {
      this.closeModalConfirm()
    },

    async handleEditOrder() {
      this.loadingEdit = true

      try {
        await axiosIns.put(`/api/v1/sgq/separation_part/update/${this.id}`, { status: 'FECHADO' })

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Pedido fechado!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          showConfirmButton: false,
          text: 'Erro ao editar pedido',
          timer: 3000,
        })
      } finally {
        this.loadingEdit = false

        this.closeModalConfirm()

        this.updatedTable()
      }
    },
  },
}
</script>
<style scoped>
.container-general{
  background: #fff;
  padding: 30px;
  width: 400px;
  height: 300px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2{
    color: #545454
  }
  span{
    color: #545454
  }
}

.container-actions{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn-not{
  color: #fff;
  background: #FF4854;
  width: 90px;
  height: 40px;
  border-radius: 5px;
}

.btn-yes{
  background: #00AA4D;
  width: 90px;
  height: 40px;
  border-radius: 5px;

  span{
    color: #fff;
  }
}
</style>
