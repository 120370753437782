<template>
  <di>
    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span class="title-page">Separação de peças</span>

        <v-spacer />

        <v-text-field
          class="mt-6"
          label="Buscar por N° pedido, responsável, data e loja"
          outlined
          dense
        />

        <v-btn
          color="info"
          class="ml-4"
          outlined
          @click="showModalAddOrder = true"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          NOVO REGISTRO
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listData"
        :loading="isLoadingData"
        no-data-text="Nenhum registro para exibir"
        loading-text="Carregando dados..."
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            medium
            class="me-2"
            color="warning"
            @click="handleGetDataById(item)"
          >
            {{ icons.mdiEye }}
          </v-icon>

          <v-icon
            medium
            class="me-2"
            :color="item.status === 'FECHADO' ? 'secondary' : 'success'"
            @click="item.status === 'ABERTO' ? handleOpenModalConfirmStatus(item) : undefined"
          >
            {{ item.status === 'FECHADO' ? icons.mdiLock : icons.mdiLockOpenVariant }}
          </v-icon>
        </template>

        <template v-slot:item.status="{ item }">
          <v-alert
            class="badge"
            text
            :color="getStatusColor(item)"
          >
            {{ item.status ? item.status : '' }}
          </v-alert>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showModalAddOrder"
      width="800px"
    >
      <ModalAddOrder
        :close-modal-add="() => showModalAddOrder = false"
        :updated-table="() => handleListData()"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalEditOrder"
      width="800px"
    >
      <ModalEditOrder
        :id="idToEditOrder"
        :key="idToEditOrder"
        :close-modal-edit="closeModalEdit"
        :updated-table="() => handleListData()"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalConfirm"
      width="400px"
    >
      <ModalConfirmStatus
        :id="idToConfirm"
        :key="idToConfirm"
        :close-modal-confirm="closeModalConfirmOrder"
        :updated-table="() => handleListData()"
      />
    </v-dialog>
  </di>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiClipboardText, mdiEye, mdiFilterPlusOutline, mdiFilterRemoveOutline,
  mdiLock,
  mdiLockOpenOutline,
  mdiLockOpenVariant,
  mdiLockOutline,
  mdiPlaylistPlus,
} from '@mdi/js'
import ModalAddOrder from './ModalAddOrder.vue'
import ModalConfirmStatus from './ModalConfirmStatus.vue'
import ModalEditOrder from './ModalEditOrder.vue'

export default {
  components: {
    ModalAddOrder,
    ModalEditOrder,
    ModalConfirmStatus,
  },

  data() {
    return {
      showModalConfirm: false,
      idToConfirm: '',
      showModalEditOrder: false,
      idToEditOrder: '',
      listData: [],
      isLoadingData: false,
      showModalAddOrder: false,
      icons: {
        mdiLock,
        mdiLockOpenVariant,
        mdiEye,
        mdiPlaylistPlus,
        mdiClipboardText,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
        mdiLockOpenOutline,
        mdiLockOutline,
      },

      value: [
        {
          pedido: 'sasddasdad',
          responsavel: 'dsadadalçsdd',
          data: 'adadsadad',
          loja: 'imperatriz',
          status: 'ABERTO',
        },
        {
          pedido: 'sasddasdad',
          responsavel: 'dsadadalçsdd',
          data: 'adadsadad',
          loja: 'imperatriz',
          status: 'FECHADO',
        },
      ],

      headers: [
        {
          text: 'N° PEDIDO',
          value: 'request_number',
          sortable: false,
          align: 'left',
        },
        {
          text: 'RESPONSÁVEL',
          value: 'user.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA',
          value: 'created_at',
          sortable: true,
          align: 'center',
        },
        {
          text: 'LOJA',
          value: 'user.company.fantasy_name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'OPÇÕES',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },

  created() {
    this.handleListData()
  },

  methods: {
    closeModalEdit() {
      this.showModalEditOrder = false
    },

    closeModalConfirmOrder() {
      // this.idToEditOrder = ''

      this.showModalConfirm = false
    },

    handleOpenModalConfirmStatus(item) {
      this.showModalConfirm = true

      this.idToConfirm = item.id
    },

    async handleListData() {
      this.isLoadingData = true

      // this.idToEditOrder = ''
      // this.idToConfirm = ''

      try {
        const response = await axiosIns.get('/api/v1/sgq/separation_part/index')

        this.listData = response.data.data
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao carregar os dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.isLoadingData = false
      }
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormatted = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormatted
    },

    getStatusColor(item = { status: '' }) {
      const { status } = item

      const statusColorMap = {
        ABERTO: 'orange',
        FECHADO: 'info',
      }

      return statusColorMap[status] ?? ''
    },

    handleGetDataById(item) {
      this.showModalEditOrder = true

      this.idToEditOrder = item.id
    },
  },
}
</script>
<style lang="scss">
.badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-page{
  font-size: 20px;
  color: #CAC9CD
}
</style>
